import React from "react"
import SEO from "../components/seo"
import Intro from "../components/page-intro"
import Helmet from "react-helmet"
import { motion } from "framer-motion"

const duration = 0.25

const container = {
  visible: {
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.08,
      delayChildren: duration,
    },
  },
}


const NotFoundPage = () => {

  return (
    <>
      <SEO 
        title="404: Page not found" 
        description="This page doesn't seem to exist."
      />
      <Helmet
        htmlAttributes={{
          class: 'not-found text-white bg-black',
        }}
      />
      
      <div className="w-full">
      <motion.section
        variants={container}
        initial="hidden" 
        animate="visible"
      >

        <Intro 
          bgColor=""
          borderColor="border-black border-opacity-10"
          introHeader={["404 —"]}
          introSubHeader={["Are you lost?"]}
          introSummary="The page you're looking for doesn't seem to exist...">
        </Intro>  

      </motion.section>
      </div>

      <div className="grid grid-cols-bx md:grid-cols-bx-md lg:grid-cols-bx-lg xl:grid-cols-bx-xl xxl:grid-cols-bx-xxl">

        <div className="border-b"></div>

        <div className="border-b border-l">

          

        </div>

        <div className="border-b border-l"></div>
      </div>
      
    </>
  )
}



export default NotFoundPage

